.notfound {
  margin: 0 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notfound__title {
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 40px;
}

.notfound__subtitle {
  font-weight: 500;
  margin-bottom: 50px;
  font-size: 25px;
}
