@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
:root {
  --black-color: hsl(0, 0%, 0%);
  --white-color: hsl(0, 0%, 100%);
  --grey-color: hsla(0, 0%, 0%, 0.8);
  --background-color: hsl(0, 0%, 85%);
  --yellow-color: hsl(53, 96%, 64%);
  --shadow-color: hsla(240, 100%, 0%, 0.56);
}

body {
  margin: 0;
  color: hsl(0, 0%, 0%);
  color: var(--black-color);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: hsl(0, 0%, 0%);
  color: var(--black-color);
}

iframe {
  width: 100%;
  height: auto;
}

.view {
  padding-top: 20px;
}

@media screen and (min-width: 800px) {
  a:hover {
    text-decoration: underline;
  }

  .view {
    padding-top: 50px;
  }
}

.home {
  padding: 20px 0px 50px 0px;
}

.project__list {
  margin-top: 25px;
  padding: 0 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}

.project__newwrapper {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.project__wrapper {
  width: 100%;
  padding-bottom: calc(100% / (16 / 9));
  margin-bottom: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--background-color);
}

.project__container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.project__client,
.project__categories {
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: var(--black-color);
}

.project__client {
  text-transform: uppercase;
}

.project__categories {
  text-transform: lowercase;
}

@media screen and (min-width: 900px) {
  .home {
    padding: 50px 200px 95px 200px;
  }

  .project__newwrapper {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }

  .project__wrapper {
    width: 100%;
    /* padding-bottom: calc(56.25% / 2); */
    /* margin-bottom: 40px; */
  }

  .project__newwrapper:nth-child(even) {
    margin-left: 20px;
  }

  .project__newwrapper:nth-child(odd) {
    margin-right: 20px;
  }

  .project__client,
  .project__categories {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: var(--white-color);
    text-shadow: 0px 0px 20px var(--shadow-color);
  }

  .project__list {
    margin-top: 80px;
    padding: 0;
  }

  .project__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0;
    z-index: 5;
    transition: opacity 500ms ease-in-out;
  }

  .project__newwrapper:hover .project__container {
    opacity: 1;
  }
}

.slideshow {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 120px;
}

.slideshow__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

.slide-enter {
  opacity: 0;
}
.slide-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.slide-exit-done {
  visibility: hidden;
}

.slideshow__link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.slideshow__project {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.slideshow__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.slideshow__titles {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.slideshow__title {
  position: absolute;
  color: var(--white-color);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  text-shadow: 0px 0px 10px var(--black-color);
}

.slideshow__subtitles {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 20px;
}

.slideshow__client,
.slideshow__categories {
  font-size: 10px;
  font-weight: 500;
  text-shadow: 0px 0px 20px var(--shadow-color);
}

.slideshow__client {
  text-transform: uppercase;
}

.slideshow__categories {
  text-transform: lowercase;
}

.slideshow__arrows {
  position: absolute;
  top: calc(100% + 17px);
  background-color: var(--white-color);
  color: var(--black-color);
  padding: 3px 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.slideshow__arrow {
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
}

.slideshow__arrow:first-child {
  border-right: solid 1px #9a9a9a;
}

@media screen and (min-width: 900px) {
  .slideshow {
    margin-bottom: 70px;
  }

  .slideshow__title {
    font-size: 46px;
    line-height: 60px;
    padding: 0;
  }

  .slideshow__subtitles {
    margin-bottom: 40px;
  }

  .slideshow__client,
  .slideshow__categories {
    font-size: 18px;
  }

  .slideshow__arrows {
    position: absolute;
    right: 50px;
    padding: 10px 0;
    bottom: 0;
    top: auto;
    left: auto;
    -webkit-transform: none;
            transform: none;
  }

  .slideshow__arrow {
    padding: 10px 20px;
  }
}

.category {
  display: -webkit-flex;
  display: flex;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category::-webkit-scrollbar {
  display: none;
}

.category--wrapper {
  margin: 0 50px;
}

.category__item {
  padding: 0 10px;
  color: var(--black-color);
  text-decoration: none;
  white-space: nowrap;
  text-transform: lowercase;
  font-size: 14px;
}

.category__item:first-child {
  padding-left: 20px;
}

.category__item:last-child {
  padding-right: 20px;
}

.category__item:hover,
.category__item--active {
  text-decoration: underline;
}
@media screen and (min-width: 700px) {
  .category {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (min-width: 900px) {
  .category {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
  }

  .category--wrapper {
    margin: 0 200px;
  }

  .category__item {
    padding: 0 15px;
    font-size: 18px;
  }
}

.about {
  background-color: var(--yellow-color);
  min-height: 100vh;
  transition: all 500ms ease-in-out;
}

.about .category {
  display: none;
}

.about-enter {
  opacity: 0;
  -webkit-transform: translate3d(0, -20%, 0);
          transform: translate3d(0, -20%, 0);
}

.about-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.about-exit {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.about-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(0, -20%, 0);
          transform: translate3d(0, -20%, 0);
}

.about__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 30px;
}

.about__info {
  padding: 0 30px;
  width: 100%;
  margin-bottom: 30px;
}

.about__description {
  font-size: 14px;
  line-height: 24px;
}

.about__description > * {
  margin-top: 14px;
}

.about__description > *:first-child {
  margin-top: 0;
}

.about__more {
  width: 100%;
  padding: 0 30px 50px 30px;
}

.about__subtitle {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 30px;
  font-weight: 500;
}

.about__festival {
  font-size: 14px;
  line-height: 24px;
}

.about__festival > * {
  margin-top: 14px;
}

.about__festival > *:first-child {
  margin-top: 0;
}

.about__wrapper a {
  color: var(--grey-color);
  transition: color 500ms ease-in-out;
  text-decoration: underline;
}

.about__wrapper a:hover {
  color: var(--black-color);
}

/* Overlays */

.shape-overlays {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.shape-overlays.is-opened {
  pointer-events: auto;
}

.shape-overlays__path {
  fill: var(--yellow-color);
}

@media screen and (min-width: 900px) {
  .about .category {
    display: -webkit-flex;
    display: flex;
  }

  .about__wrapper {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-top: 190px;
  }

  .about__info {
    width: 60%;
    padding: 0 55px 0 50px;
    margin-bottom: 0;
  }

  .about__description {
    position: fixed;
    top: 265px;
    left: 0;
    width: 60%;
    padding: 0 55px 0 50px;
    font-size: 24px;
    line-height: 34px;
  }

  .about__subtitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
  }

  .about__more {
    width: 40%;
    padding: 0 65px 100px 50px;
  }
}

.project__page .category {
  display: none;
}

.project__video {
  width: 100%;
  margin: 0 0 20px 0;
}

div[data-oembed-type='video'] div,
.project__video--container {
  width: 100%;
  padding-bottom: calc(100% / (16 / 9));
  position: relative;
}

.project__video--container div {
  background-color: white !important;
}

div[data-oembed-type='video'] div iframe {
  position: absolute;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project__slideshow,
.slider {
  margin: 0 0 20px 0;
}

.slider {
  z-index: 12;
}

.project__slides {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.project__slide {
  width: calc(100vw);
  padding-bottom: calc(100vw / (16 / 9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project {
  margin: 0 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.project__title {
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.project__description {
  text-align: center;
}

.project__description > * {
  margin-top: 18px;
}

.project__description > *:first-child {
  margin-top: 0;
}

.project__description h1 {
  font-size: 23px;
}

.project__description p {
  font-size: 14px;
  line-height: 24px;
}

.project__next {
  color: var(--black-color);
  text-decoration: none;
  font-size: 23px;
  line-height: 60px;
  text-align: center;
  margin: 90px auto 45px auto;
}

.project__next:hover {
  text-decoration: underline;
}

.slider-control-centerright,
.slider-control-centerleft {
  position: relative !important;
  margin-top: 17px;
  padding: 10px 20px;
  display: inline-block;
  left: 50% !important;
  -webkit-transform: translateX(-100%) !important;
          transform: translateX(-100%) !important;
}

.slider-control-centerleft {
  border-right: solid 1px #9a9a9a;
}

.project__slide--prev::after,
.project__slide--next::after {
  content: '';
  display: block;
  color: var(--black-color);
  width: 20px;
  height: 20px;
  border: solid 2px var(--white-color);
}

.project__slide--prev::after {
  content: '←';
  border-top-color: transparent;
  border-right-color: transparent;
}

.project__slide--next::after {
  content: '→';
  border-top-color: transparent;
  border-left-color: transparent;
}

.slider-control-bottomcenter {
  display: none;
}

@media screen and (min-width: 900px) {
  .project__page .category {
    display: -webkit-flex;
    display: flex;
  }

  .project__video {
    width: auto;
    margin: 58px 200px 20px 200px;
  }

  .project__slideshow,
  .slider {
    margin: 58px 0 20px 0;
  }

  .project {
    margin: 0 200px;
  }

  .project__slide {
    width: calc(100vw - 400px);
    padding-bottom: calc((100vw - 400px) / (16 / 9));
  }

  .project__title {
    font-size: 46px;
  }

  .project__description h1 {
    font-size: 46px;
  }

  .project__description p {
    font-size: 18px;
    line-height: 26px;
  }

  .project__next {
    font-size: 46px;
  }

  .slider-control-centerright,
  .slider-control-centerleft {
    position: absolute !important;
    top: 0 !important;
    bottom: 0;
    -webkit-transform: none !important;
            transform: none !important;
    width: 200px;
    margin-top: 0;
    padding: 0;
  }

  .slider-control-centerleft {
    border-right: none;
    left: 0 !important;
  }

  .slider-control-centerright {
    left: auto !important;
    right: 0 !important;
  }

  .project__slide--prev,
  .project__slide--next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .project__slide--prev::after,
  .project__slide--next::after {
    content: none;
  }
}

.header {
  display: -webkit-flex;
  display: flex;
}

.header__logo,
.header__contact,
.header__contact:focus {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black-color);
  text-decoration: none;
}

.header__item--logo {
  padding: 32px 20px 0 20px;
  -webkit-flex: 0 1;
          flex: 0 1;
  top: 0;
  left: 0;
  font-size: 18px;
}

.header__item--logo--hide {
  display: none;
}

.header__item--contact {
  -webkit-flex: 0 1;
          flex: 0 1;
  top: 0;
  right: 0;
  padding: 32px 20px 0px 20px;
  margin-left: auto;
}

.header__contant--mobile span {
  border: solid 1px var(--black-color);
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  padding: 2px 12px;
  position: relative;
  width: 40px;
  display: inline-block;
}

.header__contant--mobile span.cross {
  height: 20px;
}

.header__contant--mobile span.cross::after,
.header__contant--mobile span.cross::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 1px;
  background-color: var(--black-color);
}

.header__contant--mobile span.cross::after {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.header__contant--mobile span.cross::before {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.header__contact--tablet {
  display: none;
}

.header__contact #liquid-distortion-component {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.header__contact #liquid-distortion-component canvas:last-child {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.header__logo span:first-child {
  margin-right: 30px;
}

.header__logo span:last-child {
  text-align: right;
}

.footer {
  margin: 45px 0 115px 0;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer a {
  color: var(--black-color);
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 900px) {
  .header__item {
    position: fixed;
    z-index: 10;
  }

  .header__item--logo--hide {
    display: block;
  }

  .header__item--contact--relative {
    position: absolute;
  }

  .header__item--logo {
    padding: 50px 50px 0 50px;
  }

  .header__item--contact {
    padding: 50px 65px 0px 50px;
  }

  .header__contant--mobile {
    display: none;
  }

  .header__contact--tablet {
    display: block;
  }
}

.notfound {
  margin: 0 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.notfound__title {
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 40px;
}

.notfound__subtitle {
  font-weight: 500;
  margin-bottom: 50px;
  font-size: 25px;
}

