.project__page .category {
  display: none;
}

.project__video {
  width: 100%;
  margin: 0 0 20px 0;
}

div[data-oembed-type='video'] div,
.project__video--container {
  width: 100%;
  padding-bottom: calc(100% / (16 / 9));
  position: relative;
}

.project__video--container div {
  background-color: white !important;
}

div[data-oembed-type='video'] div iframe {
  position: absolute;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project__slideshow,
.slider {
  margin: 0 0 20px 0;
}

.slider {
  z-index: 12;
}

.project__slides {
  display: inline-flex;
}

.project__slide {
  width: calc(100vw);
  padding-bottom: calc(100vw / (16 / 9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project {
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project__title {
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.project__description {
  text-align: center;
}

.project__description > * {
  margin-top: 18px;
}

.project__description > *:first-child {
  margin-top: 0;
}

.project__description h1 {
  font-size: 23px;
}

.project__description p {
  font-size: 14px;
  line-height: 24px;
}

.project__next {
  color: var(--black-color);
  text-decoration: none;
  font-size: 23px;
  line-height: 60px;
  text-align: center;
  margin: 90px auto 45px auto;
}

.project__next:hover {
  text-decoration: underline;
}

.slider-control-centerright,
.slider-control-centerleft {
  position: relative !important;
  margin-top: 17px;
  padding: 10px 20px;
  display: inline-block;
  left: 50% !important;
  transform: translateX(-100%) !important;
}

.slider-control-centerleft {
  border-right: solid 1px #9a9a9a;
}

.project__slide--prev::after,
.project__slide--next::after {
  content: '';
  display: block;
  color: var(--black-color);
  width: 20px;
  height: 20px;
  border: solid 2px var(--white-color);
}

.project__slide--prev::after {
  content: '←';
  border-top-color: transparent;
  border-right-color: transparent;
}

.project__slide--next::after {
  content: '→';
  border-top-color: transparent;
  border-left-color: transparent;
}

.slider-control-bottomcenter {
  display: none;
}

@media screen and (min-width: 900px) {
  .project__page .category {
    display: flex;
  }

  .project__video {
    width: auto;
    margin: 58px 200px 20px 200px;
  }

  .project__slideshow,
  .slider {
    margin: 58px 0 20px 0;
  }

  .project {
    margin: 0 200px;
  }

  .project__slide {
    width: calc(100vw - 400px);
    padding-bottom: calc((100vw - 400px) / (16 / 9));
  }

  .project__title {
    font-size: 46px;
  }

  .project__description h1 {
    font-size: 46px;
  }

  .project__description p {
    font-size: 18px;
    line-height: 26px;
  }

  .project__next {
    font-size: 46px;
  }

  .slider-control-centerright,
  .slider-control-centerleft {
    position: absolute !important;
    top: 0 !important;
    bottom: 0;
    transform: none !important;
    width: 200px;
    margin-top: 0;
    padding: 0;
  }

  .slider-control-centerleft {
    border-right: none;
    left: 0 !important;
  }

  .slider-control-centerright {
    left: auto !important;
    right: 0 !important;
  }

  .project__slide--prev,
  .project__slide--next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project__slide--prev::after,
  .project__slide--next::after {
    content: none;
  }
}
