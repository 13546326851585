.slideshow {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 120px;
}

.slideshow__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

.slide-enter {
  opacity: 0;
}
.slide-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.slide-exit-done {
  visibility: hidden;
}

.slideshow__link {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.slideshow__project {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slideshow__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.slideshow__titles {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideshow__title {
  position: absolute;
  color: var(--white-color);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  text-shadow: 0px 0px 10px var(--black-color);
}

.slideshow__subtitles {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 20px;
}

.slideshow__client,
.slideshow__categories {
  font-size: 10px;
  font-weight: 500;
  text-shadow: 0px 0px 20px var(--shadow-color);
}

.slideshow__client {
  text-transform: uppercase;
}

.slideshow__categories {
  text-transform: lowercase;
}

.slideshow__arrows {
  position: absolute;
  top: calc(100% + 17px);
  background-color: var(--white-color);
  color: var(--black-color);
  padding: 3px 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.slideshow__arrow {
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
}

.slideshow__arrow:first-child {
  border-right: solid 1px #9a9a9a;
}

@media screen and (min-width: 900px) {
  .slideshow {
    margin-bottom: 70px;
  }

  .slideshow__title {
    font-size: 46px;
    line-height: 60px;
    padding: 0;
  }

  .slideshow__subtitles {
    margin-bottom: 40px;
  }

  .slideshow__client,
  .slideshow__categories {
    font-size: 18px;
  }

  .slideshow__arrows {
    position: absolute;
    right: 50px;
    padding: 10px 0;
    bottom: 0;
    top: auto;
    left: auto;
    transform: none;
  }

  .slideshow__arrow {
    padding: 10px 20px;
  }
}
