.home {
  padding: 20px 0px 50px 0px;
}

.project__list {
  margin-top: 25px;
  padding: 0 20px;
  display: flex;
  flex-flow: row wrap;
}

.project__newwrapper {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.project__wrapper {
  width: 100%;
  padding-bottom: calc(100% / (16 / 9));
  margin-bottom: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--background-color);
}

.project__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project__client,
.project__categories {
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: var(--black-color);
}

.project__client {
  text-transform: uppercase;
}

.project__categories {
  text-transform: lowercase;
}

@media screen and (min-width: 900px) {
  .home {
    padding: 50px 200px 95px 200px;
  }

  .project__newwrapper {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }

  .project__wrapper {
    width: 100%;
    /* padding-bottom: calc(56.25% / 2); */
    /* margin-bottom: 40px; */
  }

  .project__newwrapper:nth-child(even) {
    margin-left: 20px;
  }

  .project__newwrapper:nth-child(odd) {
    margin-right: 20px;
  }

  .project__client,
  .project__categories {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: var(--white-color);
    text-shadow: 0px 0px 20px var(--shadow-color);
  }

  .project__list {
    margin-top: 80px;
    padding: 0;
  }

  .project__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 5;
    transition: opacity 500ms ease-in-out;
  }

  .project__newwrapper:hover .project__container {
    opacity: 1;
  }
}
