.about {
  background-color: var(--yellow-color);
  min-height: 100vh;
  transition: all 500ms ease-in-out;
}

.about .category {
  display: none;
}

.about-enter {
  opacity: 0;
  transform: translate3d(0, -20%, 0);
}

.about-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.about-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.about-exit-active {
  opacity: 0;
  transform: translate3d(0, -20%, 0);
}

.about__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.about__info {
  padding: 0 30px;
  width: 100%;
  margin-bottom: 30px;
}

.about__description {
  font-size: 14px;
  line-height: 24px;
}

.about__description > * {
  margin-top: 14px;
}

.about__description > *:first-child {
  margin-top: 0;
}

.about__more {
  width: 100%;
  padding: 0 30px 50px 30px;
}

.about__subtitle {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 30px;
  font-weight: 500;
}

.about__festival {
  font-size: 14px;
  line-height: 24px;
}

.about__festival > * {
  margin-top: 14px;
}

.about__festival > *:first-child {
  margin-top: 0;
}

.about__wrapper a {
  color: var(--grey-color);
  transition: color 500ms ease-in-out;
  text-decoration: underline;
}

.about__wrapper a:hover {
  color: var(--black-color);
}

/* Overlays */

.shape-overlays {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.shape-overlays.is-opened {
  pointer-events: auto;
}

.shape-overlays__path {
  fill: var(--yellow-color);
}

@media screen and (min-width: 900px) {
  .about .category {
    display: flex;
  }

  .about__wrapper {
    flex-direction: row;
    padding-top: 190px;
  }

  .about__info {
    width: 60%;
    padding: 0 55px 0 50px;
    margin-bottom: 0;
  }

  .about__description {
    position: fixed;
    top: 265px;
    left: 0;
    width: 60%;
    padding: 0 55px 0 50px;
    font-size: 24px;
    line-height: 34px;
  }

  .about__subtitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
  }

  .about__more {
    width: 40%;
    padding: 0 65px 100px 50px;
  }
}
