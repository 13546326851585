.header {
  display: flex;
}

.header__logo,
.header__contact,
.header__contact:focus {
  display: flex;
  flex-direction: column;
  color: var(--black-color);
  text-decoration: none;
}

.header__item--logo {
  padding: 32px 20px 0 20px;
  flex: 0;
  top: 0;
  left: 0;
  font-size: 18px;
}

.header__item--logo--hide {
  display: none;
}

.header__item--contact {
  flex: 0;
  top: 0;
  right: 0;
  padding: 32px 20px 0px 20px;
  margin-left: auto;
}

.header__contant--mobile span {
  border: solid 1px var(--black-color);
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  padding: 2px 12px;
  position: relative;
  width: 40px;
  display: inline-block;
}

.header__contant--mobile span.cross {
  height: 20px;
}

.header__contant--mobile span.cross::after,
.header__contant--mobile span.cross::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 1px;
  background-color: var(--black-color);
}

.header__contant--mobile span.cross::after {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.header__contant--mobile span.cross::before {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.header__contact--tablet {
  display: none;
}

.header__contact #liquid-distortion-component {
  display: flex;
  flex-direction: column;
}

.header__contact #liquid-distortion-component canvas:last-child {
  align-self: flex-end;
}

.header__logo span:first-child {
  margin-right: 30px;
}

.header__logo span:last-child {
  text-align: right;
}

.footer {
  margin: 45px 0 115px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.footer a {
  color: var(--black-color);
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 900px) {
  .header__item {
    position: fixed;
    z-index: 10;
  }

  .header__item--logo--hide {
    display: block;
  }

  .header__item--contact--relative {
    position: absolute;
  }

  .header__item--logo {
    padding: 50px 50px 0 50px;
  }

  .header__item--contact {
    padding: 50px 65px 0px 50px;
  }

  .header__contant--mobile {
    display: none;
  }

  .header__contact--tablet {
    display: block;
  }
}
