@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

:root {
  --black-color: hsl(0, 0%, 0%);
  --white-color: hsl(0, 0%, 100%);
  --grey-color: hsla(0, 0%, 0%, 0.8);
  --background-color: hsl(0, 0%, 85%);
  --yellow-color: hsl(53, 96%, 64%);
  --shadow-color: hsla(240, 100%, 0%, 0.56);
}

body {
  margin: 0;
  color: var(--black-color);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--black-color);
}

iframe {
  width: 100%;
  height: auto;
}

.view {
  padding-top: 20px;
}

@media screen and (min-width: 800px) {
  a:hover {
    text-decoration: underline;
  }

  .view {
    padding-top: 50px;
  }
}
