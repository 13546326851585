.category {
  display: flex;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category::-webkit-scrollbar {
  display: none;
}

.category--wrapper {
  margin: 0 50px;
}

.category__item {
  padding: 0 10px;
  color: var(--black-color);
  text-decoration: none;
  white-space: nowrap;
  text-transform: lowercase;
  font-size: 14px;
}

.category__item:first-child {
  padding-left: 20px;
}

.category__item:last-child {
  padding-right: 20px;
}

.category__item:hover,
.category__item--active {
  text-decoration: underline;
}
@media screen and (min-width: 700px) {
  .category {
    justify-content: center;
  }
}

@media screen and (min-width: 900px) {
  .category {
    flex-flow: row wrap;
    align-items: center;
  }

  .category--wrapper {
    margin: 0 200px;
  }

  .category__item {
    padding: 0 15px;
    font-size: 18px;
  }
}
